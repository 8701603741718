/**
 * Helper function to format date for API.
 * Formats date to a YYYY-MM-DD date string
 */
export function formatDateToTypeN(date: Date) {
    const timezoneOffset = date.getTimezoneOffset();
    const formattedDate = new Date(date.getTime() - timezoneOffset * 60000)
        .toISOString()
        .split('T')[0];
    return formattedDate;
}

/**
 * Sets a timer that calls a function when completed
 * @param targetDate The end time as a Date object
 * @param onTimeOut Function to call when timer reaches 0
 * @param logTime Console logs remaining time
 */
export function timer(targetDate: Date, onTimeOut: () => void, logTime: boolean = false) {
    const interval = setInterval(() => {
        const remainingMs = targetDate.getTime() - new Date().getTime();
        if (logTime) console.info(remainingMs);
        if (remainingMs <= 0) {
            onTimeOut();
            clearInterval(interval);
        }
    }, 1000);

    return interval;
}

/**
 * Formats seconds to 'MM:SS' string format
 */
export function formatSecondsToMMSS(seconds: number) {
    if (Number.isNaN(seconds)) seconds = 0;
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor((seconds % 3600) % 60);
    const min = m < 10 ? '0' + m : m;
    const sec = s < 10 ? '0' + s : s;
    return min + ':' + sec;
}
