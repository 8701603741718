import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ContentWithSidebarLayout } from '../../components/layout';
import { Dropdown, InputButton, Thumbnail, VideoPlayer } from '../../components/common';
import { CameraContext } from '../../contexts/CameraContext';
import useMediaQuery from '../../hooks/useMediaQuery';
import useFetchVideos from '../../hooks/useFetchVideos';
import './Videos.scss';

function Videos() {
    const location = useLocation();
    const navigate = useNavigate();
    const { isMobileDevice } = useMediaQuery();
    const { sessionCookies, selectedCamera } = useContext(CameraContext);
    const { videos, isFetchingData } = useFetchVideos(sessionCookies, selectedCamera, location);

    const [selectedVideos, setSelectedVideos] = useState<Video[]>([]);
    const [videoPlaying, setVideoPlaying] = useState<Video>();
    const currentUrl = window.location.pathname;
    const onVideoPlayer = currentUrl === '/videos/player';

    /**
     * Resets all states
     */
    const resetState = () => {
        setSelectedVideos([]);
        setVideoPlaying(undefined);
    };

    /**
     * On browser back, reset the Videos page
     */
    useEffect(() => {
        window.addEventListener('popstate', () => setVideoPlaying(undefined));
    }, []);

    /**
     * Clear video selection when camera changes
     */
    useEffect(() => {
        resetState();
        if (selectedCamera && location.pathname === '/videos/player') {
            history.back();
        }
    }, [selectedCamera]);

    return (
        <ContentWithSidebarLayout
            className="videos"
            leftSidebarContent={leftSidebarContent()}
            mainContent={mainContent()}
            rightSidebarContent={rightSidebarContent()}
        />
    );

    function leftSidebarContent() {
        const sidebarRoutes = [
            {
                label: 'Project Record',
                url: '/videos'
            },
            {
                label: 'Monthly Progress',
                url: '/videos/monthly'
            }
        ];
        const dropdownOptions = Object.values(sidebarRoutes).map((item) => ({
            label: item.label,
            value: item.url
        }));

        return (
            <div className="videos__lsc">
                {isMobileDevice() ? (
                    <Dropdown
                        className="video-dropdown"
                        value={dropdownOptions[0].value}
                        options={dropdownOptions}
                        onChange={(url) => navigate(url)}
                    />
                ) : (
                    sidebarRoutes.map((item) => (
                        <Link
                            key={item.url}
                            to={item.url}
                            className={`${currentUrl === item.url ? 'active' : ''}`}
                            onClick={resetState}
                        >
                            {item.label}
                        </Link>
                    ))
                )}
            </div>
        );
    }

    function mainContent() {
        // On video player
        if (currentUrl === '/videos/player') {
            return (
                <div className="videos__player">
                    <VideoPlayer src={videoPlaying?.videoUrl} />
                    {isMobileDevice() && rightSidebarContent()}
                </div>
            );
        }

        // Loading
        if (selectedCamera && isFetchingData) {
            return <div className="empty-thumbnails">Loading...</div>;
        }

        // No thumbnails
        if (selectedCamera && videos.length === 0) {
            return <div className="empty-thumbnails">No thumbnails</div>;
        }

        /**
         * Handle selection of video thumbnails.
         *
         * Note: Commented out code handles selection of multiple videos.
         * It has been changed to single video selection until multi-video
         * download can function in staging. Currently doesn't work due
         * to browser security and will require backend pre-bundling of
         * videos to work.
         */
        const onVideoSelect = (video: Video) => {
            // const updatedVideoSelection = selectedVideos.includes(video)
            //     ? selectedVideos.filter((x) => x.videoUrl !== video.videoUrl)
            //     : [...selectedVideos, video];
            // setSelectedVideos(updatedVideoSelection);
            setSelectedVideos([video]);
        };

        return (
            <div className={`videos__thumbnails ${isMobileDevice() ? 'col-2' : 'col-3'}`}>
                {videos.map((video, index) => {
                    const lazyLoadThreshold = 9;
                    const doLazyLoad = index + 1 > lazyLoadThreshold;
                    const isActive = selectedVideos.includes(video);

                    return (
                        <Thumbnail
                            key={video.thumbnailUrl}
                            src={video.thumbnailUrl}
                            label={video.name}
                            width={640}
                            height={427}
                            isActive={isActive}
                            lazyLoad={doLazyLoad}
                            showSelectionCheckbox
                            fetchpriority="high"
                            onClick={() => onVideoSelect(video)}
                            onDoubleClick={() => {
                                setVideoPlaying(video);
                                navigate('/videos/player');
                            }}
                        />
                    );
                })}
            </div>
        );
    }

    function rightSidebarContent() {
        /**
         * Downloads selected video
         */
        const handleVideoDownload = () => {
            const videosToDownload = videoPlaying ? [videoPlaying] : selectedVideos;
            videosToDownload.forEach(async (video) => downloadHelper(video.downloadVideoUrl));

            function downloadHelper(url: string) {
                const link = document.createElement('a');
                link.href = url;
                link.download = ''; // filename can only be declared server-side
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        };

        return (
            <div className="videos__rsc">
                <InputButton
                    className="videos__rsc__download"
                    type="button"
                    label="Download"
                    onClick={handleVideoDownload}
                    disabled={selectedVideos.length === 0}
                />
                {onVideoPlayer && (
                    <InputButton
                        className="videos__rsc__back"
                        type="button"
                        label="Back to Videos"
                        onClick={() => history.back()}
                    />
                )}
            </div>
        );
    }
}

export default Videos;
