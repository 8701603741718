import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Dropdown } from '../../common';

function NavigationDropdown() {
    const navigate = useNavigate();
    const location = useLocation();
    const dropdownOptions = ['Images', 'Comparison', 'Videos'].map((x) => ({
        label: x,
        value: x.toLowerCase()
    }));
    const [selectedDropdown, setSelectedDropdown] = useState<string>(dropdownOptions[0].value);

    // Reads the current URL and set Dropdown accordingly
    useEffect(() => {
        const path = location.pathname.toLowerCase();
        const pageName = dropdownOptions.find(({ value }) => path.includes(value.toLowerCase()));
        if (pageName) {
            setSelectedDropdown(pageName.value);
        }
    }, [location]);

    // Navigate to selected page on dropdown selection
    const handleDropdownSelect = (key: string) => {
        const url = `/${key}`;
        navigate(url);
    };

    return (
        <Dropdown
            value={selectedDropdown}
            options={dropdownOptions}
            onChange={(e) => handleDropdownSelect(e)}
        />
    );
}

export default NavigationDropdown;
