/**
 * Sorts an array of InputOptions by label, with labels starting with an underscore
 * at the end of the array.
 */
export function sortInputOptionsUnderscoreLast(arr: InputOption[]) {
    interface InputOptionObj { [key: string]: InputOption[] }
    const { noUnderscore, underscore }: InputOptionObj = arr.reduce(
        (acc: InputOptionObj, obj: InputOption) => {
            if (obj.label.charAt(0) === '_') {
                acc.underscore.push(obj);
            } else {
                acc.noUnderscore.push(obj);
            }
            return acc;
        },
        { noUnderscore: [], underscore: [] }
    );

    const sortedNoUnderscore = noUnderscore.sort((a, b) => a.label.localeCompare(b.label));
    const sortedUnderscore = underscore.sort((a, b) => a.label.localeCompare(b.label));

    return [...sortedNoUnderscore, ...sortedUnderscore];
}
