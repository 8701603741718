import './InputToggle.scss';

interface InputToggleProps extends InputProps {
    /**
     *  Label text left to the control
     */
    label?: string;
    /**
     * Additional classname of containing div
     */
    className?: string;
    /**
     * Checked state of the checkbox
     */
    checked?: boolean;
    /**
     * Additional details below the checkbox
     */
    subtitle?: string;
}

/**
 * A toggle slider/switch component.
 */
function InputToggle({ label, className = '', checked, subtitle, onChange }: InputToggleProps) {
    return (
        <div data-testid="InputToggle" className={`input-toggle ${className}`}>
            <span className="input-toggle__toggle">
                {label && <span>{label}</span>}
                {/* eslint-disable-next-line */}
                <label className="input-toggle__toggle__switch">
                    <input
                        type="checkbox"
                        checked={checked}
                        onChange={(e) => onChange && onChange(e.target.value)}
                    />
                    <span className="input-toggle__toggle__switch__slider" />
                </label>
            </span>
            {subtitle && <p className="input-toggle__subtitle">{subtitle}</p>}
        </div>
    );
}

export default InputToggle;
