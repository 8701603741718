import { useState } from 'react';
import useMediaQuery from '../../../hooks/useMediaQuery';
import './Thumbnail.scss';

interface ThumbnailProps {
    /**
     * Img src
     */
    src?: string;
    /**
     * Text overlayed at the buttom of the image
     */
    label?: string;
    /**
     * Img width
     */
    width?: number;
    /**
     * Img height
     */
    height?: number;
    /**
     * Img max height
     */
    maxHeight?: number;
    /**
     * Element ref
     */
    reff?: React.MutableRefObject<HTMLImageElement>;
    /**
     * If true, add 'active' classname
     */
    isActive?: boolean;
    /**
     * if true, lazy-load the image
     */
    lazyLoad?: boolean;
    /**
     * if true, show selection in top-right
     */
    showSelectionCheckbox?: boolean;
    /**
     * Browser fetch priority
     */
    fetchpriority?: 'high' | 'low' | 'auto';
    /**
     * Callback function when thumbnail is clicked
     */
    onClick?: () => void;
    /**
     * Callback function when thumbnail is double-clicked
     */
    onDoubleClick?: () => void;
}

/**
 *  A button displaying an image
 */
function Thumbnail({
    src,
    label,
    width,
    height,
    maxHeight,
    reff,
    isActive,
    fetchpriority = 'auto',
    lazyLoad = false,
    showSelectionCheckbox,
    onClick,
    onDoubleClick
}: ThumbnailProps) {
    const { isMobileDevice } = useMediaQuery();
    const [loaded, setLoaded] = useState(false);
    let timer: NodeJS.Timeout | undefined;

    function handleOnClick() {
        if (!isMobileDevice()) {
            onClick && onClick();
            return;
        }
        // Handles mobile double tap
        if (timer !== undefined) {
            onClick && onClick();
            onDoubleClick && onDoubleClick();
            clearTimeout(timer);
            timer = undefined;
        } else {
            timer = setTimeout(() => {
                onClick && onClick();
                clearTimeout(timer);
                timer = undefined;
            }, 300);
        }
    }

    return (
        <div className="thumbnail">
            {/* {loaded && showSelectionCheckbox && (
                <input
                    type="checkbox"
                    checked={isActive}
                    onClick={handleOnClick}
                    onDoubleClick={onDoubleClick}
                    readOnly
                />
            )} */}
            <img
                ref={reff}
                className={`${isActive ? 'active' : ''}`}
                src={src}
                width={width}
                height={height}
                alt="Thumbnail"
                loading={lazyLoad ? 'lazy' : 'eager'}
                onLoad={() => setLoaded(true)}
                onClick={handleOnClick}
                onDoubleClick={onDoubleClick}
                style={{ maxHeight }}
                // @ts-ignore
                // eslint-disable-next-line
                fetchpriority={fetchpriority}
            />
            {label && loaded && (
                <span>
                    <p>{label}</p>
                </span>
            )}
        </div>
    );
}

export default Thumbnail;
