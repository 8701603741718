import axios from 'axios';
import { useEffect, useState } from 'react';
import { Location } from 'react-router-dom';
import { VideoType } from '../types/enums';
import { SessionCookieState } from './useSessionCookies';

export interface VideoState {
    videos: Video[];
    videoType: VideoType;
    fetchCameraVideos: (videoType: VideoType) => void;
    isFetchingData?: boolean | undefined,
}

/**
 * Used to get videos and video-related data from the API
 */
const useFetchVideos = (
    sessionCookies: SessionCookieState,
    camera?: Camera,
    location?: Location
): VideoState => {
    const { refreshCookies } = sessionCookies;
    const [videos, setVideos] = useState<Video[]>([]);
    const [videoType, setVideoType] = useState<VideoType>(getVideoType());
    const [isFetchingData, setIsFetchingData] = useState<boolean>(false);

    // When the camera or window location changes, fetch a new list of videos
    useEffect(() => {
        setVideos([]);
        const vidType = getVideoType();
        setVideoType(vidType);
        if (camera) {
            fetchCameraVideos(vidType);
        }
    }, [camera, location?.pathname]);

    /**
     * Fetch an updated list of camera videos
     */
    const fetchCameraVideos = async (vidType: VideoType) => {
        setIsFetchingData(true);
        const videoUrl = camera?.[vidType];
        if (!videoUrl) return;

        axios
            .get(videoUrl)
            .then(({ status, data }) => {
                if (status === 200) {
                    let resVideos;
                    switch (vidType) {
                        case VideoType.MONTHLY:
                            // There is no monthly videos returned as I can see
                            resVideos = data.monthlies;
                            break;
                        case VideoType.DAILY:
                            resVideos = data.dailies;
                            break;
                        default:
                            resVideos = [];
                    }

                    // const sortedVideos = resVideos;
                    const sortedVideos = resVideos.sort((a: Video, b: Video) => {
                        const aDate = new Date(a.name);
                        const bDate = new Date(b.name);
                        return bDate.getTime() - aDate.getTime();
                    });
                    setVideos(sortedVideos);
                    setIsFetchingData(false);
                    refreshCookies();
                } else {
                    setIsFetchingData(false);
                    throw new Error('Response not 200');
                }
            })
            .catch((err) => console.error(err));
    };

    /**
     * Checks the window's path and returns the video type
     */
    function getVideoType() {
        const path = window.location.href.toLowerCase();
        if (path && path.includes('/videos/monthly')) {
            return VideoType.MONTHLY;
        }
        return VideoType.DAILY;
    }

    return {
        videos,
        videoType,
        fetchCameraVideos,
        isFetchingData,
    };
};

export default useFetchVideos;
